var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('v-expansion-panels',{style:(_vm.cssProps),attrs:{"accordion":"","active-class":"menu-main"},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',{attrs:{"active-class":"menu-panel"}},[_c('v-expansion-panel-header',{staticClass:"menu",staticStyle:{"border-radius":"0"},style:({
        'background-color': _vm.item.bgColor ? _vm.item.bgColor : _vm.item.isPrimary ? _vm.config.BGColor : _vm.config.BGColorSecondary,
        'color': _vm.item.textColor ? _vm.item.textColor : _vm.item.isPrimary ? _vm.config.Color : _vm.config.ColorSecondary
      }),attrs:{"hide-actions":"","id":_vm.item.id}},[_c('div',{staticClass:"menu-container"},[_c('div',{staticClass:"menu-simulated"},[_c('center',[_c('v-icon',{staticStyle:{"margin-right":"5px"},style:({
              'font-size': _vm.config.FontSize + 'px'
            }),attrs:{"color":_vm.item.textColor ? _vm.item.textColor : _vm.item.isPrimary ? _vm.config.Color : _vm.config.ColorSecondary}},[_vm._v(" mdi-shopping-outline ")]),_vm._v(" "+_vm._s(_vm.getTranslation(_vm.item.name, _vm.locale)))],1)],1)])]),_c('v-expansion-panel-content',{staticClass:"menu-content",style:({
        'background-color': _vm.item.bgColorContent ? _vm.item.bgColorContent : _vm.config.BGColorContent,
        'color': _vm.item.textColorContent ? _vm.item.textColorContent : _vm.config.ColorContent
      }),attrs:{"id":_vm.item.id.concat('content')}},[_c('list',{attrs:{"locale":_vm.locale,"config":_vm.config,"currency":_vm.currency,"note":_vm.note,"percentage":_vm.percentage,"allInclusive":_vm.allInclusive,"formConfirm":_vm.formConfirm}})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }